import axios from 'axios'
import axiosOptions from './AxiosOptions'

class Lecture {
    allRecords(){
        return axios.get(apiRoute + "lecture", axiosOptions.optionsWithAuth)
    }
    typeOnly(){
        return axios.get(apiRoute + "lecture/TypeOnly", axiosOptions.optionsWithAuth)
    }
    UpdateRecord(selectedLecture){
        return axios.put(apiRoute + "lecture/" + selectedLecture.id, selectedLecture, axiosOptions.optionsWithAuth)
    }
    SaveRecord(selectedLecture){
        return axios.post(apiRoute + "lecture", selectedLecture, axiosOptions.optionsWithAuth)
    }
    DeleteRecord(selectedLecture){
        return axios.delete(apiRoute + 'lecture/' + selectedLecture.id, axiosOptions.optionsWithAuth)
    }
    loadSubjects(){
        return axios.get(apiRoute+'subject', axiosOptions.optionsWithAuth)
    }
    getClasses() {
        return axios.get(apiRoute + 'eclass', axiosOptions.optionsWithAuth)
    }
    getAllWithLiveClasses(){
        return axios.get(apiRoute + 'eclass/allwithlivelecture', axiosOptions.optionsWithAuth)

    }
    getClassSection(id) {
        return axios.get(apiRoute + 'eclass/' + id + '/classSection', axiosOptions.optionsWithAuth)
    }
    getSession() {
        return axios.get(apiRoute + 'session', axiosOptions.optionsWithAuth)
    }
    loadUnits(id){
        return axios.get(apiRoute + 'subject/' + id + '/find_units', axiosOptions.optionsWithAuth)
    }
    uploadFile(data){
        return axios.post(apiRoute + 'formSubmit/lecture_thumbnail', data, axiosOptions.optionsWithAuthAndFiles)
    }
    getLiveLectures() {
        return axios.get(apiRoute + 'live_lectures', axiosOptions.optionsWithAuth)
    }
    getTeachers() 
    {
        return axios.get(apiRoute + 'get_user_by_role/teacher', axiosOptions.optionsWithAuth)
    }
}
export default Lecture = new Lecture()
