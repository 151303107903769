import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: [{
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/institute',
            name: 'institute',
            component: () =>
                import ('./views/Institute.vue')
        },
        {
            path: '/catagory',
            name: 'catagory',
            component: () =>
                import ('./views/Catagory.vue')
        },
        {
            path: '/download',
            name: 'download',
            component: () =>
                import ('./views/Downloads.vue')
        },
        {
            path: '/users',
            name: 'users',
            component: () =>
                import ('./views/UserList.vue')
        },
        {
            path: '/pages',
            name: 'pages',
            component: () =>
                import ('./views/Pages.vue')
        },
        {
            path: '/releases',
            name: 'releases',
            component: () =>
                import ('./views/Release.vue')
        },
        {
            path: '/article',
            name: 'article',
            component: () =>
                import ('./views/VArticle.vue')
        },
        {
            path: '/test',
            name: 'test',
            component: () =>
                import ('./views/Test.vue')
        },
        {
            path: '/test_category',
            name: 'test_category',
            component: () =>
                import ('./views/Test_Category.vue')
        },
        {
            path: '/otp/create-user/:id?',
            name: 'otp-create-user',
            component: () =>
                import ('./views/CreateUserAccount.vue')
        },
        {
          path: '/signin',
          name: 'signin',
          component: () => import('./views/Signin.vue')
        },
        {
            path: '/create-notification',
            name: 'create-notification',
            component: () =>
                import ('./views/CreateNotification.vue')
        },
        {
            path: '/show-all-notification',
            name: 'show-all-notification',
            component: () =>
                import ('./views/ShowAllNotification.vue')
        },
        {
          path: '/signout',
          name: 'signout',
          component: () => import('./views/Signout.vue')
        },
        {
          path: '/locked',
          name: 'locked',
          component: () => import('./views/Locked.vue')
        },
        {
            path: '/test_assign',
            name: 'test_assign',
            component: () =>
                import ('./views/Test_Assign.vue')
        },
        {
            path: '/talent-test',
            name: 'talent_telst',
            component: () =>
                import ('./views/TalentTest/index.vue')
        },
        {
            path: '/testtemplatelist',
            name: 'test_template_list',
            component: () =>
                import ('./views/TestTemplateList.vue')
        },
        {
            path: '/testgenerate',
            name: 'testgenerate',
            component: () =>
                import ('./views/TestGenerate.vue')
        },
        {
            path: '/ramsha',
            name: 'ramsha',
            component: () =>
                import ('./views/Ramsha.vue')
        },
        {
            path: '/gallery',
            name: 'gallery',
            component: () =>
                import ('./views/Gallery.vue')
        },
        {
            path: '/post',
            name: 'post',
            component: () =>
                import ('./views/Post/CreatePost.vue')
        },
        {
            path: '/category',
            name: 'category',
            component: () =>
                import ('./views/Category/CreateCategory.vue')
        },
        {
            path: '/post-type',
            name: 'post-type',
            component: () =>
                import ('./views/Post/CreatePostType.vue')
        },
        {
            path: '/country',
            name: 'country',
            component: () =>
                import ('./views/Country.vue')
        },
        {
            path: '/state',
            name: 'state',
            component: () =>
                import ('./views/State.vue')
        },
        {
            path: '/city',
            name: 'city',
            component: () =>
                import ('./views/City.vue')
        },
        {
            path: '/area',
            name: 'area',
            component: () =>
                import ('./views/Area.vue')
        },
        {
            path: '/question',
            name: 'question',
            component: () =>
                import ('./views/Question.vue')
        },
        {
            path: '/question/:id',
            name: 'singlequestion',
            component: () =>
                import ('./views/QuestionSingle.vue')
        },
        {
            path: '/watched-lectures',
            name: 'Watched Lectures',
            component: () =>
                import ('./views/WatchedLectures.vue')
        },
        //   {
        //     path: '/test',
        //     name: 'test',
        //     component: () =>
        //         import ('./views/Test.vue')
        // },

        {
            path: '/create-schedule',
            name: 'create-schedule',
            component: () =>
                import ('./views/Schedule.vue')
        },
        {
            path: '/schedule-details/:id',
            name: 'schedule-details',
            component: () =>
                import ('./views/ScheduleDetails.vue')
        },
        {
            path: '/branch',
            name: 'branch',
            component: () =>
                import ('./views/Branch.vue')
        },
        {
            path: '/department',
            name: 'department',
            component: () =>
                import ('./views/Department.vue')
        },
        {
            path: '/office',
            name: 'office',
            component: () =>
                import ('./views/Office.vue')
        },
        {
            path: '/section',
            name: 'section',
            component: () =>
                import ('./views/Section.vue')
        },
        {
            path: '/eclass',
            name: 'eclass',
            component: () =>
                import ('./views/EClass.vue')
        },
        {
            path: '/classSection',
            name: 'classSection',
            component: () =>
                import ('./views/ClassSection.vue')
        },
        {
            path: '/session',
            name: 'session',
            component: () =>
                import ('./views/Session.vue')
        },
        {
            path: '/loadAssign/:id',
            name: 'loadAssign',
            component: () =>
                import ('./views/LoadAssign.vue')
        },
        {
            path: '/loadAssignSingle/:id/class/:class/test/:test',
            name: 'loadAssignSingle',
            component: () =>
                import ('./views/loadAssignSingle.vue')
        },
        {
            path: '/lecture',
            name: 'lecture',
            component: () =>
                import ('./views/Lecture.vue')
        },
        {
            path: '/otp',
            name: 'otp',
            component: () =>
                import ('./views/OTP.vue')
        },
        {
            path: '/student-contact',
            name: 'studentcontact',
            component: () =>
                import ('./views/StudentContact.vue')
        },
        {
            path: '/paper',
            name: 'paper',
            component: () =>
                import ('./views/Paper.vue')
        },
        // {
        //     path: '/testkendo',
        //     name: 'kendo',
        //     component: () =>
        //         import ('./views/Kendo.vue')
        // },
        {
            path: '/testtemplate/:id?',
            name: 'testtemplate',
            component: () =>
                import ('./views/TestTemplate.vue')
        },
        {
            path: '/testsection/:id',
            name: 'testsection',
            component: () =>
                import ('./views/TestSection.vue')
        },
        // {
        //     path: '/country',
        //     name: 'country',
        //     component: () =>
        //         import ('./views/Country.vue')
        // },
        // {
        //     path: '/state',
        //     name: 'state',
        //     component: () =>
        //         import ('./views/State.vue')
        // },
        {
            path: '/activeSession',
            name: 'activeSession',
            component: () =>
            import ('./views/ActiveSession.vue')
        },
        {
            path: '/livestudents',
            name: 'livestudents',
            component: () =>
            import ('./views/LiveLectures/index.vue')
        },
        {
            path: '/todaystudentlist',
            name: 'todaystudentlist',
            component: () =>
            import ('./views/TodayStudentList.vue')
        },
        {
            path: '/assignTeachers',
            name: 'assignTeachers',
            component: () =>
            import ('./views/AssignTeachers.vue')
        },
        {
            path: '/marksheet/:studid/:ttid',
            name: 'marksheet',
            component: () =>
            import ('./views/Marksheet.vue')
        },
        {
            path: '/marksheetlist',
            name: 'marksheetlist',
            component: () =>
            import ('./views/MarksheetList.vue')
        },
        {
            path: '/change-student-class',
            name: 'change-student-class',
            component: () =>
            import ('./views/ChangeStudentClass.vue')
        },
        {
            path: '/change-student-live-class',
            name: 'change-student-live-class',
            component: () =>
            import ('./views/ChangeStudentLiveClass.vue')
        },
        {
            path: '/change-user-password',
            name: 'change-user-password',
            component: () =>
            import ('./views/ChangeUserPassword.vue')
        },
        {
            path: '/student-testimonial',
            name: 'student-testimonial',
            component: () =>
            import ('./views/StudentTestimonial.vue')
        },
        {
            path: '/assign-subjects-to-classes',
            name: 'assign-subjects-to-classes',
            component: () =>
            import ('./views/AssignSubjectsToClasses.vue')
        },
        {
            path: '/student',
            name: 'student',
            component: () =>
                import ('./views/Student.vue')
        },
        {
            path: '/studentSession',
            name: 'studentSession',
            component: () =>
                import ('./views/StudentSession.vue')
        },

        {
            path: '/subject',
            name: 'subject',
            component: () =>
                import ('./views/Subject.vue')
        },

        {
            path: '/unit',
            name: 'unit',
            component: () =>
                import ('./views/Unit.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () =>
                import ('./views/Dashboard.vue')
        },
        {
            path: '/employee',
            name: 'employee',
            component: () =>
                import ('./views/employee.vue')
        },
        {
            path: '/upload_test',
            name: 'upload_test',
            component: () =>
                import ('./views/Upload.vue')
        },
        {
            path: '/manage-live-lectures',
            name: 'Manage Live Lectures',
            component: () =>
                import ('./views/ManageLiveLectures.vue')
        },
        {
            path: '/abra/videos-list',
            name: 'videos-list',
            component: () =>
                import ('./views/VideosList.vue')
        }



        // {
        //     path: '/ramsha',
        //     name: 'ramsha',
        //     component: () =>
        //         import ('./views/Ramsha.vue')
        // },

        // {
        //     path: '/country',
        //     name: 'country',
        //     component: () =>
        //         import ('./views/Country.vue')
        // },
        // {
        //     path: '/state',
        //     name: 'state',
        //     component: () =>
        //         import ('./views/State.vue')
        // },


        //   path: '/forms',
        //   name: 'forms',
        //   component: () => import('./views/Forms.vue')
        // },
        // {
        //   path: '/profile',
        //   name: 'profile',
        //   component: () => import('./views/Profile.vue')
        // },
        // {
        //   path: '/clients/index',
        //   name: 'clients.index',
        //   component: () => import('./views/Clients/ClientsIndex.vue'),
        // },
        // {
        //   path: '/clients/new',
        //   name: 'clients.new',
        //   component: () => import('./views/Clients/ClientsForm.vue'),
        // },
        // {
        //   path: '/clients/:id',
        //   name: 'clients.edit',
        //   component: () => import('./views/Clients/ClientsForm.vue'),
        //   props: true
        // }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }

        }
    }
})
