import Vue from "vue";
import Vuex from 'vuex';
import eClassHelper from '../helpers/EClass';
import SectionHelper from '../helpers/Section';
import SessionHelper from '../helpers/Session';
import LectureHelper from '../helpers/Lecture';
import NotificationHelper from '../helpers/Notifications';
import CategoryHelper from '../helpers/Category'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
const store = new Vuex.Store({
  state() {
        return {
            classes: null,
            classSections: null,
            sessions: null,
            lectures:null,
            notifications: null,
            notificationList: null,
            allNotifications: null,
            categories: null,
        }
  },
  mutations: {
    setEClasses(state, classes) {
        state.classes = classes
    },
    setEClassSections(state, sections) {
        state.classSections = sections
    },
    setSessions(state, sessions) {
        state.sessions = sessions
    },
    setLectures(state, lectures) {
        state.lectures = lectures
    },
    setUnSeenNotifications(state, notifications) {
        state.notifications = notifications
    },
    setAllNotifications(state, notifications) {
        state.allNotifications = notifications
    },
    updateNotificationSet(state, id) {
        const newNotifications = state.notifications.filter(notification => {
            return notification.id != id
        });
        state.notifications = newNotifications;
    },
    setCategories(state, categories) {
        state.categories = categories
    },
  },
  actions: {
    fetchClasses({ commit }) {
        eClassHelper.allRecords()
        .then((res) => commit('setEClasses', res.data))
    },
    fetchEClassSections({ commit }, id) {
        SectionHelper.fetchClassSection(id)
        .then((res) => commit('setEClassSections', res.data))
    },
    fetchSessions({ commit }) {
        SessionHelper.allRecords()
        .then((res) => commit('setSessions', res.data))
    },
    fetchLectures({ commit }) {
        LectureHelper.allRecords()
        .then((res) => commit('setLectures', res.data))
    },
    sendNotifications({commit}, data) {
        return NotificationHelper.sendNotifications(data)
        .then(() => {})
    },
    sendCustomNotifications({commit}, data) {
        return NotificationHelper.sendCustomNotifications(data)
        .then(() => {})
    },
    fetchAdminUnSeenNotifications({commit}) {
        NotificationHelper.fetchAdminUnSeenNotifications()
            .then((res) => commit('setUnSeenNotifications', res.data))
    },
    fetchAdminAllNotifications({commit}) {
        NotificationHelper.fetchAdminAllNotifications()
            .then((res) => commit('setAllNotifications', res.data))
    },
    hasSeenNotification({commit}, id) {
        NotificationHelper.hasSeenNotification(id)
            .then((res) => console.log('Close Notification'))
    },
    fetchCategories({commit}) {
        CategoryHelper.index()
            .then((res) => commit('setCategories', res.data))
    }
  },
  plugins: [createPersistedState()],
})


export default store;
