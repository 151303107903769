<template>
    <b-navbar type="is-light" class="nav-shadow" fixed-top close-on-click shadow>
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="http://admin.starsentrytest.edu.pk/img/gc.png" alt="GreenBoard">
            </b-navbar-item>
        </template>
        <template slot="start" v-if="loggedIn && (isAdmin || isSuper)">
            <div class="dropdown">
                <p class="dropbtn">
                    <a v-if="notifications.length > 0" href="#"  class="w3-wrapper sdp-glow">
                        <svg class="notify-icon" viewBox="6 4 30 30">
                            <path d="M19.1818,30.5455h3.6364a0,0,0,0,1,0,0v.9091A1.8182,1.8182,0,0,1,21,33.2727h0a1.8182,1.8182,0,0,1-1.8182-1.8182v-.9091A0,0,0,0,1,19.1818,30.5455Z"></path>
                            <path d="M20.9091,8.7273h.1818a.8182.8182,0,0,1,.8182.8182v2.8182a0,0,0,0,1,0,0H20.0909a0,0,0,0,1,0,0V9.5455A.8182.8182,0,0,1,20.9091,8.7273Z"></path>
                            <path d="M21,12.5455q.2061,0,.4154.0134a6.3426,6.3426,0,0,1,5.7664,6.4486v2.9269a19.3045,19.3045,0,0,0,.8675,5.702H13.9507a19.3045,19.3045,0,0,0,.8675-5.702V18.7273A6.1887,6.1887,0,0,1,21,12.5455m0-2a8.1816,8.1816,0,0,0-8.1818,8.1818v3.2071A17.2221,17.2221,0,0,1,11,29.6364H31a17.2221,17.2221,0,0,1-1.8182-7.702V19.0075a8.368,8.368,0,0,0-7.6372-8.4444q-.274-.0177-.5446-.0176Z"></path>
                        </svg>
                        <span class="w3-count">{{ notifications.length }}</span>
                    </a>
                    <i v-else class="far fa-bell"></i>
                </p>
                <div class="dropdown-content">
                    <router-link tag="b-navbar-item" class="tag is-secondary" :to="{ name: 'create-notification' }">
                        <span><i class="far fa-paper-plane"></i> Create Notifications</span>
                    </router-link>
                    <div v-if="notifications.length > 0">
                        <a v-for="notification in notifications" :key="notification.id">
                            <b-notification aria-close-label="Close notification" @click="hasSeen(notification.id)">
                                <p> <span :class="'tag ' + notification.type"><i class="fas fa-exclamation-circle"></i></span> <span class="title is-6">{{ notification.subject }}</span> </p>
                                <p>{{ notification.body }}</p>
                                <small> {{ new Date(notification.created_at).toLocaleDateString() }} </small>
                            </b-notification>
                            <!-- <div class="notification">
                                <button class="delete" @click="hasSeen(notification.id)"></button>
                            </div> -->
                        </a>
                    </div>
                    <div>
                        <router-link to="show-all-notification">
                            <center><span class="show-all">Show all notifications</span></center>
                        </router-link>
                    </div>
                </div>
            </div>
            <router-link tag="b-navbar-item" :to="{ name: 'home' }">
                    Home
            </router-link>
              <!-- <router-link tag="b-navbar-item" to="employee">
                   Employee
            </router-link> -->
            <!-- <b-navbar-dropdown label="Location">
                <router-link to="country">
                    <b-navbar-item>
                        Country
                    </b-navbar-item>
                </router-link>
                <router-link to="state">
                    <b-navbar-item>
                        State
                    </b-navbar-item>
                </router-link>
                <router-link to="city">
                    <b-navbar-item>
                        City
                    </b-navbar-item>
                </router-link>
                <router-link to="area">
                    <b-navbar-item>
                        Area
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown> -->
            <b-navbar-dropdown label="Test">
                <router-link :to="{ name: 'test_category' }">
                    <b-navbar-item>
                        Test Category
                    </b-navbar-item>
                </router-link>
                <router-link :to="{ name: 'test' }">
                    <b-navbar-item>
                        Test
                    </b-navbar-item>
                </router-link>
                <router-link :to="{ name: 'question' }">
                    <b-navbar-item>
                        Question
                    </b-navbar-item>
                </router-link>
                <!-- <router-link to="testgenerate">
                    <b-navbar-item>
                        TestGenerate
                    </b-navbar-item>
                </router-link> -->
                <router-link :to="{ name: 'test_assign' }">
                    <b-navbar-item>
                        Manage Assigned Tests
                    </b-navbar-item>
                </router-link>
                <router-link :to="{ name: 'test_template_list' }">
                    <b-navbar-item>
                        Manage Test Templates
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Teachers">
                <router-link :to="{ name: 'assignTeachers' }">
                    <b-navbar-item>
                        Assign Students to Teachers
                    </b-navbar-item>
                </router-link>
                <router-link :to="{ name: 'marksheetlist' }">
                    <b-navbar-item>
                        Mark Tests
                    </b-navbar-item>
                    
                </router-link>
                
            </b-navbar-dropdown>
            <!-- <b-navbar-dropdown label="CMS">
                <router-link to="catagory">
                    <b-navbar-item>
                        Catagory
                    </b-navbar-item>
                </router-link>
                <router-link to="article">
                    <b-navbar-item>
                        Article
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown> -->
            <!-- <b-navbar-dropdown label="Organization">
                <router-link to="institute">
                    <b-navbar-item>
                        Institute
                    </b-navbar-item>
                </router-link>
                <router-link to="branch">
                    <b-navbar-item>
                        Branch
                    </b-navbar-item>
                </router-link>
                <router-link to="department">
                    <b-navbar-item>
                        Department
                    </b-navbar-item>
                </router-link>
                <router-link to="office">
                    <b-navbar-item>
                        Office
                    </b-navbar-item>
                </router-link>
                <router-link to="section">
                    <b-navbar-item>
                        Section
                    </b-navbar-item>
                </router-link>

            </b-navbar-dropdown>
            <b-navbar-dropdown label="Student">
                <router-link to="student">
                    <b-navbar-item>
                        Student
                    </b-navbar-item>
                </router-link>
                <router-link to="eclass">
                    <b-navbar-item>
                        Class
                    </b-navbar-item>
                </router-link>
                <router-link to="classSection">
                    <b-navbar-item>
                        Class Section
                    </b-navbar-item>
                </router-link>
                <router-link to="session">
                    <b-navbar-item>
                        Session
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown> -->
            <b-navbar-dropdown label="Lecture">
                <!-- <router-link to="studentSession">
                    <b-navbar-item>
                        Student Session
                    </b-navbar-item>
                </router-link> -->
                <router-link :to="{ name: 'lecture' }">
                    <b-navbar-item>
                        Manage Lectures
                    </b-navbar-item>
                </router-link>
                <router-link to="watched-lectures">
                    <b-navbar-item>
                        Manage Watched Lectures
                    </b-navbar-item>
                </router-link>
                <router-link tag="b-navbar-item" :to="{ name: 'assign-subjects-to-classes' }">
                    Assign Subjects to Classes
                </router-link>
                <router-link tag="b-navbar-item" :to="{ path: 'manage-live-lectures' }">
                    Manage Live Lectures
                </router-link>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Misc">
                <!-- <router-link to="studentSession">
                    <b-navbar-item>
                        Student Session
                    </b-navbar-item>
                </router-link> -->
                <router-link to="download">
                    <b-navbar-item>
                        Manage Downloads
                    </b-navbar-item>
                </router-link>
                <!-- <router-link to="category">
                    <b-navbar-item>
                        Manage Categories
                    </b-navbar-item>
                </router-link> -->
                <router-link to="post">
                    <b-navbar-item>
                        Manage Posts
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Manage Students">
                <!-- <router-link to="studentSession">
                    <b-navbar-item>
                        Student Session
                    </b-navbar-item>
                </router-link> -->
                <router-link to="activeSession">
                    <b-navbar-item>
                        Activate Student
                    </b-navbar-item>
                </router-link>
                <router-link to="livestudents">
                    <b-navbar-item>
                        Activate Live Classes Student
                    </b-navbar-item>
                </router-link>
                <router-link to="change-student-class">
                    <b-navbar-item>
                        Change Student Class
                    </b-navbar-item>
                </router-link>
                <router-link to="change-student-live-class">
                    <b-navbar-item>
                        Change Student Live Class
                    </b-navbar-item>
                </router-link>
                <router-link tag="b-navbar-item" :to="{ name: 'todaystudentlist' }">
                    Today's Registered Students
                </router-link>
                <router-link tag="b-navbar-item" :to="{ name: 'otp' }">
                        Unregistered Users
                </router-link>
                <router-link to="student-testimonial">
                    <b-navbar-item>
                        Add Student's Testimonials
                    </b-navbar-item>
                </router-link>
                <router-link tag="b-navbar-item" :to="{ name: 'otp-create-user' }">
                        Create a Student Account
                </router-link>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Schedules">
                <router-link :to="{ name: 'create-schedule' }">
                    <b-navbar-item>
                        Create Schedule
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Abra">
                <router-link :to="{ name: 'videos-list' }">
                    <b-navbar-item>
                        Videos List
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
        </template>
        <template slot="end">
            <b-navbar-item tag="div" v-if="loggedIn">
                <div class="buttons">
                    <a  @click.prevent="logOut()" class="button is-dark">
                        Log Out
                    </a>
                </div>
            </b-navbar-item>
                <!-- <b-dropdown can-close hoverable custom position="is-bottom-left"
                    label="Account" v-if="loggedIn">
                    <a class="navbar-item is-dark" slot="trigger" role="button">
                        <span style="color:grey;">Account</span>
                        <b-icon style="color:grey;" icon="menu-down"></b-icon>
                    </a>
                    <b-dropdown-item custom aria-role="menuitem">
                        Logged in as <br> <b>{{email}}</b>
                    </b-dropdown-item>
                    <hr class="dropdown-divider" aria-role="menuitem">
                    <b-dropdown-item value="settings">
                        <b-icon icon="settings"></b-icon>
                        Settings
                    </b-dropdown-item>
                    <b-dropdown-item @click="logOut()" value="logout" aria-role="menuitem">
                        <b-icon icon="logout"></b-icon>
                        Logout
                    </b-dropdown-item>
                </b-dropdown> -->
        </template>
        <!-- <template slot="end">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-primary">
                        <strong>Sign up</strong>
                    </a>
                    <a class="button is-light">
                        Log in
                    </a>
                </div>
            </b-navbar-item>
        </template> -->
    </b-navbar>
</template>
<script>
import User from '../helpers/User'

    export default {

        data() {
            return {
                instituteButton: false,
                loggedIn: User.loggedIn(),
                isAdmin: User.role() == 'admin',
                isSuper: User.role() == 'super admin'
            }
        },
        computed: {
            notifications() { return this.$store.state.notifications }
        },
        methods: {
            toogleInstituteButton () {
                this.instituteButton = false
            },
            logOut() {
                User.logOut()
            },
            hasSeen(id) {
                this.$store.dispatch('hasSeenNotification', id);
                this.$store.commit('updateNotificationSet', id);
            },
        },
        mounted(){
            if(User.loggedIn() &&
            (window.location.pathname == '/signin' ||
             window.location.pathname == '/signup'
            ))
            {
            //    this.$route.push({       name:'board'});
                this.$router.push({name:'home'});
                // console.log(this.$route)
            }
            else if (User.loggedIn() && (User.role() == 'admin' || User.role() == 'super admin'))
            {

            }
            // else if(window.location.pathname == '/signup'
            // || window.location.pathname == '/forgetpassword'
            // || window.location.pathname == '/activateaccount')
            // {
            //     // console.log(this.$route)
            // }
            else if (User.loggedIn() && (User.role() != 'admin' || User.role() != 'super admin'))
            {
                this.$router.push({name:'locked'});
            }
            else
            {
                // console.log(this.$route)
                // console.log(window.location)
                this.$router.push({name:'signin'});
            }

            EventBus.$on('logout',()=>{
                User.logOut()
            })
        },
        created() {
            this.$store.dispatch('fetchAdminUnSeenNotifications');
        },
    }

</script>

<style lang="scss">
    .dropbtn {
        padding: 16px;
        font-size: 16px;
        border: none;
        transition: 0.5s;
    }
    .dropbtn:hover{
        transition: 0.5s;
    }
    .dropdown {
        position: relative;
        display: inline-block;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 35rem;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        max-height: 50rem;
        overflow-y:scroll;
        scrollbar-width: thin;
    }
    .dropdown-content::-webkit-scrollbar {
        width: 10px;
    }
    .dropdown-content::-webkit-scrollbar-track {
        background-color: #ffffff;
    }
    .dropdown-content::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px #7957D5;
        border-radius: 5px;
    }
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        transition: 0.5s;
    }
    .notification {
        transition: 0.1s;
    }
    .notification:hover {
        transition: 0.1s;
        background-color: #7957D5;
        color: #ffffff;
        font-size: 1rem;
    }
    .dropdown-content a:hover {
        background-color: #ddd;
        transition: 0.5s;
    }
    .dropdown:hover .dropdown-content { display: block; }
    .dropdown:hover .dropbtn {
        background-color: #F5F5F5;
        color:black;
        font-size: 18px;
        font-weight: bold;
    }
    .tag.is-secondary{
        transition: 0.5s;
        font-size: 1rem;
    }
    .tag.is-secondary:hover {
        background-color: #3E8ED0;
        color:#ffffff;
        font-size: 1.3rem;
        transition: 0.5s;
    }
    .w3-wrapper{
        color: #ffffff;
        text-decoration: none;
        font-size: 15px;
        position: relative;
        padding: 11px 7px 15px 7px;
        display: block;
        border-bottom: 3px solid transparent;
        transition: all 0.3s;
    }
    .notify-icon{
        width: 25px;
        height: 25px;
        display: block;
        position: relative;
        background-position: center center;
        animation:ring 1.6s linear infinite;
    }
    .w3-count{
        position: absolute;
        padding: 2px 3px;
        background: #FF4F38;
        color: #fff;
        border-radius: 30px;
        top: 7px;
        right: 0px;
        min-width: 12px;
        font-size: 11px;
        z-index: 200;
        text-align: center;
        animation: w3-alert-red 1.6s infinite;
    }
    @keyframes w3-alert-red {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7);
            -moz-box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7);
            box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7)
        }
        100% {
            -webkit-box-shadow: 0 0 0 15px rgba(254, 57, 5, 0);
            -moz-box-shadow: 0 0 0 15px rgba(254, 57, 5, 0);
            box-shadow: 0 0 0 15px rgba(254, 57, 5, 0)
        }
    }

    @keyframes ring
    {
        0% { transform: rotate(0deg) }
        5% { transform: rotate(0deg) }
        15% { transform: rotate(0deg) }
        25% { transform: rotate(20deg) }
        35% { transform: rotate(-15deg) }
        45% { transform: rotate(10deg) }
        55% { transform: rotate(-5deg) }
        60% { transform: rotate(0deg) }
        100% { transform: rotate(0deg) }
    }
</style>
