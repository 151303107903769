<template>
    <div class="section">
        <div class="columns is-multiline">
            <div class="column">
                <div class="box">
                    <p class="title is-4">Statistics</p>
                </div>
            </div>
        </div>
        <p class="title is-5">Recent Registrations</p>
        <div class="columns is-multiline">
            <div class="column is-2">
                <router-link :to="{path: 'otp'}">
                    <div class="card">
                        <div class="card">
                            <div class="card-image">
                                <b-tooltip :label="statistics_labels.unregisteredUsers" position="is-bottom" animated>
                                    <i class="fas fa-users" id="i_size"></i>
                                </b-tooltip>
                            </div>
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <center><span class="title is-5"> <i class="fas fa-list-ol"></i> {{ data[0].unsuccessful_registrations }} </span></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="column is-2">
                <router-link :to="{path: 'otp'}">
                    <div class="card">
                        <div class="card">
                            <div class="card-image">
                                <b-tooltip :label="statistics_labels.attentedUsers" position="is-bottom" animated>
                                    <i class="fas fa-user-check" id="i_checked"></i>
                                </b-tooltip>
                            </div>
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <center><span class="title is-5"> <i class="fas fa-tasks"></i> {{ data[1].attended_users }} </span></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="column is-2">
                <router-link :to="{path: 'otp'}">
                    <div class="card">
                        <div class="card">
                            <div class="card-image">
                                <b-tooltip :label="statistics_labels.bannedUser" position="is-bottom" animated>
                                    <i class="fas fa-user-slash" id="i_size"></i>
                                </b-tooltip>
                            </div>
                            <div class="card-content">
                                <div class="media">
                                    <div class="media-content">
                                        <center><span class="title is-5"> <i class="fas fa-list-ol"></i> {{ data[2].banned_users }} </span></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import StatsHelper from '../helpers/Statistics'
export default {
    name: "home",
    data() {
        return {
            statistics_labels: {
                unregisteredUsers: 'List of unsuccessful registrations',
                attentedUsers: 'List of attented users',
                bannedUser: 'List of banned users'
            },
            data: []
        }
    },
    methods: {
        userRegistrationCounts() {
            StatsHelper.fetchStats()
                .then(res => {
                    this.data = res.data
                })
        },
    },
    mounted() {
        this.userRegistrationCounts();


    },
    created() {
        if(this.$store.state.classes == null)
            this.$store.dispatch('fetchClasses');
        if(this.$store.state.sessions == null)
            this.$store.dispatch('fetchSessions');
        if(this.$store.state.categories == null)
            this.$store.dispatch('fetchCategories')
    },

};
</script>
<style lang="scss">
    .card-image{
        padding-top: 1rem;
        text-align: center;
    }
    #i_size{
        font-size: 3rem;
        color: #FC6D26;
    }
    #i_checked{
        font-size: 3rem;
        color: #82C91E;
    }
    .card{
        background-color:white;
        transition: 0.5s;
    }
    .title.is-5{
        transition: 0.5s;
    }
    .card:hover{
        background-color: #AEDDFF;
        transition: 0.5s;
    }
    .title.is-5:hover{
        font-size:25px;
        transition: 0.5s;
    }
</style>
