import axios from 'axios'
import axiosOptions from './AxiosOptions'

class EClass {

    allRecords() {
        return axios.get(apiRoute + 'eclass', axiosOptions.optionsWithAuth)
    }
    allActive() {
        return axios.get(apiRoute + 'eclass/getall', axiosOptions.optionsWithAuth)
    }

    UpdateRecord(form) {
        return axios.put(apiRoute + "eclass/" + form.id, form, axiosOptions.optionsWithAuth)
    }
    SaveRecord(form) {
        return axios.post(apiRoute + "eclass", form, axiosOptions.optionsWithAuth)
    }
    DeleteRecord(form) {
        return axios.delete(apiRoute + 'eclass/' + form.id, axiosOptions.optionsWithAuth)
            // return axios.post(apiRoute+"download",form,axiosOptions.optionsWithAuth)
    }
    getLiveLecture() {
        return axios.get(apiRoute + "eclass/livelecture", axiosOptions.optionsWithAuth)
    }


}
export default EClass = new EClass()