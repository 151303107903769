import Token from './Token'
import axios from 'axios'
import AppStorage from './AppStorage'
import AxiosObjects from './AxiosOptions'
import {
    required,
    minLength
} from "vuelidate/lib/validators";

import router from '../router';

class User {

    login(form) {
        return axios.post(apiRoute + 'auth/login', form, AxiosObjects.options)
    }
    signup(form) {
        return axios.post(apiRoute+'auth/signup', form, AxiosObjects.options)
    }

    responseAfterLogin(res) {
        const access_token = res.data.access_token
        const name = res.data.name
        const email = res.data.email
        const uclass = res.data.class
        console.log(res.data)
        if (Token.isValid(access_token)) {
            AppStorage.storeUser(access_token, name, email,uclass)
            console.log("Storing Token")
            window.location = '/home'
                // this.id()
        }
    }

    hasToken() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            console.log("Checking Token Validity...")
            return Token.isValid(storedToken) ? true : false
        }

        return false
    }


    isDemoAccount() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            console.log("Checking Account Validity...")
            return Token.getUserStatus(storedToken) ? true : false
        }

        return false
    }


    loggedIn() {
        if (this.hasToken()) {
            // console.log('user is already Logged In')
            return true
        } else {
            // console.log('user is not Logged In')
            return false
        }
    }

    isAdmin(){
        if(this.role() == 'admin')
            return true
        else
            return false
    }

    logOut() {
        AppStorage.clearUser()
        window.location = '/signin'
    }

    name() {
        if (this.loggedIn())
            return AppStorage.getUser();
    }

    email() {
        if (this.loggedIn())
            return AppStorage.getEmail();
    }

    class() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            console.log("Checking Account Validity...")
            return Token.getUserClass(storedToken);
        }

        return 'Null'
    }

    role() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            console.log("Checking Account Validity...")
            return Token.getUserRole(storedToken);
        }

        return 'Null'
    }

    changePasswordWithoutToken(form) {
        return axios.post(apiRoute + 'auth/changePassword', form)
    }

    token() {
        if (this.loggedIn()) {
            console.log('Got Token from storage')
            return AppStorage.getToken();
        }
    }


    id() {
        if (this.loggedIn()) {
            const payload = Token.payload(AppStorage.getToken())
                // console.log(payload)
                // console.log("Everything is valid")
            return payload.user_id
        }
    }

    //Education
    getUserEducation() {
        return axios.get(apiRoute + 'user_educations/' + this.id() + '/users');
        // return axios.get(apiRoute+'user_educations' );
    }
    saveEducation(education) {

        return axios.post(apiRoute + 'user_educations', education)
    }
    updateEducation(education) {

        axios.put(apiRoute + 'user_educations/' + education.id, education)
    }
    deleteEducation(education) {

            axios.delete(apiRoute + 'user_educations/' + education.id, education)

        }
        //Experience
    getUserExperience() {
        return axios.get(apiRoute + 'user_experiences/' + this.id() + '/users');

    }
    saveExperience(experience) {

        return axios.post(apiRoute + 'user_experiences', experience)
    }
    updateExperience(experience) {

        axios.put(apiRoute + 'user_experiences/' + experience.id, experience)
    }
    deleteExperience(experience) {

        axios.delete(apiRoute + 'user_experiences/' + experience.id, experience)

    }

    //Personal Information

    getUserPersonalInformation() {
        return axios.get(apiRoute + 'user_personal_info/' + this.id() + '/users');

    }
    savePersonalInformation(personalInformation) {

        return axios.post(apiRoute + 'user_personal_info', personalInformation)
    }
    updatePersonalInformation(personalInformation) {

        axios.put(apiRoute + 'user_personal_info/' + personalInformation.id, personalInformation)
    }
    deletePersonalInformation(personalInformation) {

        axios.delete(apiRoute + 'user_personal_info/' + personalInformation.id, personalInformation)

    }

}

export default User = new User();
