import axios from 'axios'
import axiosOptions from './AxiosOptions'
class Notifications {

    allRecords() {
        return axios.get(apiRoute + 'notifications/list', axiosOptions.optionsWithAuth)
    }
    allRecordsCustom() {
        return axios.get(apiRoute + 'notifications/list/custom', axiosOptions.optionsWithAuth)
    }
    sendNotifications(data) {
        return axios.post(apiRoute + "notifications", data, axiosOptions.optionsWithAuth)
    }
    sendCustomNotifications(data) {
        return axios.post(apiRoute + "notifications/custom", data, axiosOptions.optionsWithAuth)
    }
    fetchAdminUnSeenNotifications() {
        return axios.get(apiRoute + "get_admin_notifications/unseen", axiosOptions.optionsWithAuth)
    }
    fetchAdminAllNotifications() {
        return axios.get(apiRoute + "get_admin_notifications", axiosOptions.optionsWithAuth)
    }
    hasSeenNotification(id) {
        return axios.get(apiRoute + "close_notification_for_admin/" + id, axiosOptions.optionsWithAuth)
    }


}
export default Notifications = new Notifications()
