<template>
<div>
        <NavTop />
        <!-- <div class="columns">
            <div class="column is-one-fifth">
                <SideBar/>
            </div>
                <div class="column">
                </div>
        </div> -->
                <router-view :key="$route.fullPath"/>
    <!-- <div class="columns">
        <div >
           <SideBar />  class="is-one-third"
        </div> -->
        <!-- </div> -->
    <!-- </div> -->

</div>
</template>
<script>
import NavTop  from './components/NavTop';
import SideBar from './components/SideBar';

export default {
    components:{
        NavTop,
        SideBar
    },
    data(){
        return{
            currentPath:window.location.pathname,
        }
    }
}

</script>
