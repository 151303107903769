import axios from 'axios'
import axiosOptions from './AxiosOptions'

class Session {

    allRecords() {
        return axios.get(apiRoute + 'session', axiosOptions.optionsWithAuth)
    }

    UpdateRecord(form) {
        return axios.post(apiRoute + "session/" + form.id, form, axiosOptions.optionsWithAuthAndFiles)
    }
    SaveRecord(form) {
        return axios.post(apiRoute + "session", form, axiosOptions.optionsWithAuthAndFiles)
    }
    DeleteRecord(form) {
        return axios.delete(apiRoute + 'session/' + form.id, axiosOptions.optionsWithAuth)
            // return axios.post(apiRoute+"download",form,axiosOptions.optionsWithAuth)
    }


}
export default Session = new Session()