import Vue from "vue";
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import eClassHelper from '../helpers/EClass';
import SectionHelper from '../helpers/Section';
import SessionHelper from '../helpers/Session';
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        classes: null,
        classSections: null,
        sessions: null,
    },
    mutations: {
        setEClasses(state, classes) {
            state.classes = classes
        },
        setEClassSections(state, sections) {
            state.classSections = sections
        },
        setSessions(state, sessions) {
            state.sessions = sessions
        },
    },
    actions: {
        fetchClasses({ commit }) {
            eClassHelper.allRecords()
            .then((res) => commit('setEClasses', res.data))
        },
        fetchEClassSections({ commit }, id) {
            SectionHelper.fetchClassSection(id)
            .then((res) => commit('setEClassSections', res.data))
        },
        fetchSessions({ commit }) {
            SessionHelper.allRecords()
            .then((res) => commit('setSessions', res.data))
        },
    },
    plugins: [createPersistedState()],
})


export default store;
