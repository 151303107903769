import axios from 'axios'
import axiosOptions from './AxiosOptions'

class Statistics {

    fetchStats() {
        return axios.get(apiRoute + 'dashboard/stats', axiosOptions.optionsWithAuth)
    }

}
export default Statistics = new Statistics()
