import axios from 'axios'
import axiosOptions from './AxiosOptions'

class Section {

    allRecords() {
        return axios.get(apiRoute + 'section', axiosOptions.optionsWithAuth)
    }

    UpdateRecord(form) {
        return axios.post(apiRoute + "section/" + form.id, form, axiosOptions.optionsWithAuthAndFiles)
    }
    SaveRecord(form) {
        return axios.post(apiRoute + "section", form, axiosOptions.optionsWithAuthAndFiles)
    }
    DeleteRecord(form) {
        return axios.delete(apiRoute + 'section/' + form.id, axiosOptions.optionsWithAuth)
            // return axios.post(apiRoute+"download",form,axiosOptions.optionsWithAuth)
    }
    fetchClassSection(id) {
        return axios.get(apiRoute + 'eclass/' + id + '/classSection', axiosOptions.optionsWithAuth)
    }


}
export default Section = new Section()
