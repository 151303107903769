import axios from 'axios'
import axiosOptions from './AxiosOptions'

class Category {

    index() {
        return axios.get(apiRoute + 'category', axiosOptions.optionsWithAuth)
    }
    store(data) {
        return axios.post(apiRoute + 'category', data, axiosOptions.optionsWithAuth)
    }
}
export default Category = new Category()