class AppStorage {
    storeToken(token) {
        localStorage.setItem('token', token)
    }

    storeUsername(name) {
        localStorage.setItem('user', name)
    }

    storeEmail(email) {
        localStorage.setItem('email', email)
    }

    storeClass(uclass) {
        localStorage.setItem('class', uclass)
    }

    storeUser(token,name,email,uclass){
        this.storeToken(token)
        this.storeUsername(name)
        this.storeEmail(email)
        this.storeClass(uclass)
    }

    clearUser()
    {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('class')
    }

    getToken()
    {
        return localStorage.getItem('token')
    }

    getUser()
    {
        return localStorage.getItem('user');
    }

    getEmail()
    {
        return localStorage.getItem('email');
    }

    getClass()
    {
        return localStorage.getItem('class');
    }
}

export default AppStorage = new AppStorage();
