import Vue from "vue";
import Buefy from "buefy";
import "@progress/kendo-ui";
import "buefy/dist/buefy.min.css";
import Store from './store/index'
import SharedStore from './store/SharedStore'
import { DatePicker,DateTimePicker } from "@progress/kendo-vue-dateinputs";
Vue.component("datepicker", DatePicker);
Vue.component("datetimepicker", DateTimePicker);

import "@progress/kendo-theme-default/dist/all.css";

import { Upload, UploadInstaller } from "@progress/kendo-upload-vue-wrapper";
Vue.use(UploadInstaller);

import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
Vue.component("Grid", Grid);
Vue.component("grid-toolbar", GridToolbar);

import { process } from '@progress/kendo-data-query';


// MDI
import '@mdi/font/css/materialdesignicons.css'
// Font-awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
// import Vue from 'vue'
// import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify';
import "vuetify/dist/vuetify.min.css";

// Vue.use(Vuetify)
// KENDO DATES
// import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { DatePicker } from '@progress/kendo-vue-dateinputs';
// import { IntlProvider, load, loadMessages, LocalizationProvider }
// from '@progress/kendo-vue-intl';

// Vue.component('datepicker', DatePicker);
// Vue.component('dropdownlist', DropDownList);
// Vue.component('intl', IntlProvider);
// Vue.component('localization', LocalizationProvider);
// // import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
// // import currencyData from 'cldr-core/supplemental/currencyData.json';
// import weekData from 'cldr-core/supplemental/weekData.json';

// // import numbers from 'cldr-numbers-full/main/es/numbers.json';
// // import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
// import dateFields from 'cldr-dates-full/main/es/dateFields.json';
// import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';

// load(
//     // likelySubtags,
//     // currencyData,
//     weekData,
//     numbers,
//     // caGregorian,
//     dateFields,
//     timeZoneNames
// );

// import esMessages from './app/es.json';
// loadMessages(esMessages, 'es-ES');
// const locales = [
//     {
//         language: 'en-US',
//         locale: 'en'
//     },
//     {
//         language: 'es-ES',
//         locale: 'es'
//     }
// ];

// KENDO DATES END

// import CKEditor from '@ckeditor/ckeditor5-vue';

import Dayjs from './plugins/day.js'

// Router.js
import router from "./router.js";
import store from "./store.js";

// Main Entry View
import App from "./App.vue";

window.EventBus = new Vue();

/* Buefy */
Vue.use(Buefy);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
//Vue.use(window.vuelidate.default)

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

// Vue.use( CKEditor );
/* Main component */
Vue.component("App", App);
Vue.use(Vuelidate);

window.Vue = Vue;

window.TestTemplateId = "";
window.StudentId = "";

//----------------- /* API URL */ Local-Test
// Vue.prototype.$apiRoute  = "http://greenboard.test/api/";
// window.apiRoute          = "http://greenboard.test/api/";
// Vue.prototype.$webRoute  = "http://greenboard.test/api/";
// window.webRoute          = "http://greenboard.test/api/";
// Vue.prototype.$imageRoot = "http://greenboard.test/";
// window.imageRoot         = "http://greenboard.test/";
// Vue.prototype.$imageRoot = 'http://zukhruf.globalteksoft.com/public/';
// window.imageRoot         = 'http://zukhruf.globalteksoft.com/public/';

//----------------- /* API URL */ Local 2
// Vue.prototype.$apiRoute  = "http://greenboard-l9.test/api/";
// window.apiRoute          = "http://greenboard-l9.test/api/";
// Vue.prototype.$webRoute  = "http://greenboard-l9.test/api/";
// window.webRoute          = "http://greenboard-l9.test/api/";
// Vue.prototype.$imageRoot = "http://greenboard-l9.test/";
// window.imageRoot         = "http://greenboard-l9.test/";
// Vue.prototype.$imageRoot = 'http://zukhruf.globalteksoft.com/public/';
// window.imageRoot         = 'http://zukhruf.globalteksoft.com/public/';

//----------------- /* API URL */ Local-8000
// Vue.prototype.$apiRoute  = "http://127.0.0.1:8000/api/";
// window.apiRoute          = "http://127.0.0.1:8000/api/";
// Vue.prototype.$webRoute  = "http://127.0.0.1:8000/api/";
// window.webRoute          = "http://127.0.0.1:8000/api/";
// Vue.prototype.$imageRoot = "http://greenboard.test/";
// window.imageRoot         = "http://greenboard.test/";
// Vue.prototype.$imageRoot = 'http://zukhruf.globalteksoft.com/public/';
// window.imageRoot         = 'http://zukhruf.globalteksoft.com/public/';

// window.apiRoot            = 'http://greenboard-l9.test/'
// window.apiRoute           = 'http://greenboard-l9.test/api/'
// Vue.prototype.$apiRoute   = 'http://greenboard-l9.test/api/'
// window.imageRoute         = 'http://greenboard-l9.test/'
// Vue.prototype.$imageRoute = 'http://greenboard-l9.test/'

//----------------- /* API URL */ Development
// Vue.prototype.$apiRoute = 'http://api.starsentrytest.edu.pk/api/'
// window.apiRoute         = 'http://api.starsentrytest.edu.pk/api/'
// Vue.prototype.$webRoute = 'http://api.starsentrytest.edu.pk/'
// window.webRoute         = 'http://api.starsentrytest.edu.pk/'

//----------------- /* API URL */ Production
// Vue.prototype.$apiRoute  = 'http://zukhruf.globalteksoft.com/api/'
// window.apiRoute          = 'http://zukhruf.globalteksoft.com/api/'
// Vue.prototype.$webRoute  = 'http://zukhruf.globalteksoft.com/'
// window.webRoute          = 'http://zukhruf.globalteksoft.com/'
// Vue.prototype.$imageRoot = 'http://zukhruf.globalteksoft.com/public/'
// window.imageRoot         = 'http://zukhruf.globalteksoft.com/public/'

//----------------- /* API URL */ Production v2
Vue.prototype.$apiRoute  = 'http://zukhrufv2.globalteksoft.com/api/'
window.apiRoute          = 'http://zukhrufv2.globalteksoft.com/api/'
Vue.prototype.$webRoute  = 'http://zukhrufv2.globalteksoft.com/'
window.webRoute          = 'http://zukhrufv2.globalteksoft.com/'
Vue.prototype.$imageRoot = 'http://zukhrufv2.globalteksoft.com/public/'
window.imageRoot         = 'http://zukhrufv2.globalteksoft.com/public/'

//----------------- /* API URL */ Production v3
// Vue.prototype.$apiRoute  = 'htstp://zukhrufv3.starsentrytest.edu.pk/api/'
// window.apiRoute          = 'http://zukhrufv3.starsentrytest.edu.pk/api/'
// Vue.prototype.$webRoute  = 'http://zukhrufv3.starsentrytest.edu.pk/'
// window.webRoute          = 'http://zukhrufv3.starsentrytest.edu.pk/'
// Vue.prototype.$imageRoot = 'http://zukhrufv3.tarsentrytest.edu.pk/public/'
// window.imageRoot         = 'http://zukhrufv3.starsentrytest.edu.pk/public/'

window.Vue = Vue;

new Vue({
    store,
    Upload,
    router,
    Dayjs,
    store: Store,
    SharedStore: SharedStore,
    vuetify,
    render: h => h(App)
}).$mount("#app");
