class Token {
    payload(token) {
        const payload = token.split('.')[1]
        return this.decode(payload)
    }

    isValid(token) {
        const payload = this.payload(token)
        const auth = window.apiRoute + "auth/login";
        const signup = window.apiRoute + "auth/signup"
        if (payload)
            if (payload.iss == auth || signup) {
                // var iattime = new Date(payload.iat*1000)
                // var exptime = new Date(payload.iat*1000)
                // var currentTime = new Date();
                // if(exptime > currentTime)
                // {
                //     console.log("Not Validated")
                //     // console.log(payload)
                //     return false
                // }
                // console.log("Iat:"+ iattime.getTime())
                // console.log("Exp:"+ exptime.getTime())
                // console.log("Cur:"+ currentTime.getTime())
                console.log("Validated")
                return true
            } else {
                console.log("Not Validated")
                    console.log(payload)
                return false
            }
    }

    getUserClass(token){
        const payload = this.payload(token)
        if (payload)
            return payload.class
    }
    getUserRole(token){
        const payload = this.payload(token)
        if (payload)
            return payload.role
    }

    getUserStatus(token){
        const payload = this.payload(token)
        if (payload)
            if(payload.isverified == 0)
                return true
            else
                return false
    }

    decode(payload) {
        //console.log(JSON.parse(atob(payload)))
        return JSON.parse(atob(payload))
    }
}

export default Token = new Token();
