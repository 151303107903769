import User from './User'
class AxiosObjects{

   get optionsWithAuth (){
      const obj = { headers: {'Authorization': 'Bearer '+User.token() ,'Access-Control-Allow-Origin': '*'}}
      return obj
   };
   get optionsWithAuthAbra (){
      const obj = { 
         headers: {
            'Authorization': 'Bearer '+User.token(),
            'Access-Control-Allow-Origin': '*',
            'accept': 'appication/json',
            'content-type': 'application/*+json',
            'AccessKey': '072a64c5-0738-476f-b3ae8cd1a144-f33d-4bdd'
         }
      }
         
      return obj
   };
   get optionsWithAuthAndFiles (){

       const obj = { headers: {'Accept':'application/json','Authorization': 'Bearer '+User.token() ,'Access-Control-Allow-Origin': '*','Content-Type':'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL'}}
       return obj
    };
   get options (){
      const obj = { headers: {'Access-Control-Allow-Origin': '*','Content-Type':'application/json'}}
      return obj
   };
}
export default AxiosObjects = new AxiosObjects();
