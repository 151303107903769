import Vue from "vue";
import Dayjs from 'vue-dayjs';

Vue.use(Dayjs, {
  // language set, default cn
  lang: 'en',
  /**
   * addon filters { key: filter name }
   * if set {} will only dayjs base filter can use.
   */
  filters: {
    ago: 'ago'
  },
  /**
   * addon directives { key: directives name }
   * set {} to disable.
   */
  directives: {
    countdown: 'countdown'
  }
});

export default Dayjs